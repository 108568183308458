<template>
  <div>
    <p class="pt-2 pl-3 text-lg font-bold sm:text-white"><span class="typcn typcn-edit"></span> Produk/Barang</p>
    <hr class="mt-1 mb-1">
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class=" col-sm-12 p-2" style="font-size:12px;">
          <div class="shadow p-3 rounded-lg bg-white">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="dataproduk.id"
              disabled
            />
          </div>
          <div v-show="lihatbarcode">
              <svg id="barcode2"></svg>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <table class="table table-sm table-responsive">
            <tr>        
                <td>
                  
                  Barcode 
                </td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <button type="button"  @click="lihatbarcode=!lihatbarcode" class="btn btn-sm btn-dark p-1 float-right"><span class="typcn typcn-arrow-up"></span></button>
                        <button type="button" v-if="cordova"  @click="scanning" class="btn btn-sm btn-dark p-1 float-right"><span class="typcn typcn-code"></span></button>
                        <input style="width:75%;font-size:12px;" @change="generateBarcode2" required type="text" id="barcode" name="barcode" class="form-control form-control-sm d-inline" placeholder="barcode" v-model="dataproduk.barcode" >
                    </div>
                </td>
            </tr>
             <tr>        
                <td class="capitalize">Nama Produk</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <input required type="text" id="nama_produk" name="nama_produk" class="form-control form-control-sm" placeholder="nama_produk" v-model="dataproduk.nama_produk" >
                    </div>
                </td>
            </tr>
             <tr>        
                <td class="capitalize">Kategori</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <v-select :options="tdkategori" label="kategori" v-model="dataproduk.kategori" :reduce="e => e.id">
                      <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!dataproduk.kategori"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                    </v-select>
                </td>
            </tr>
            
             <tr>        
                <td class="capitalize">satuan</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <v-select :options="satuans" label="satuan" v-model="dataproduk.satuan" :reduce="e => e.id">
                        <template #search="{attributes, events}">
                          <input
                            class="vs__search"
                            :required="!dataproduk.satuan"
                            v-bind="attributes"
                            v-on="events"
                          />
                        </template>
                    </v-select>
                </td>
            </tr>
             <tr>        
                <td class="capitalize">tag</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <input type="text" id="tag" name="tag" class="form-control form-control-sm" placeholder="ex: buah,sayur,makanan" v-model="dataproduk.tag" >
                    </div>
                </td>
            </tr>
            <tr>        
                <td class="capitalize">gambar 1</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                    <button type="button" class="btn btn-sm btn-dark  float-right"  v-if="cordova"  @click="camera1"><span class="typcn typcn-upload"></span></button>
                        <input style="width:100px;" type="file" id="gambar1" name="gambar1" class="form-control form-control-sm d-inline" placeholder="gambar1" @change="upload1" >
                    </div>
                      <input required type="text" id="gambar1" name="gambar1" class="form-control form-control-sm" placeholder="gambar1" v-model="dataproduk.gambar1">
                </td>
            </tr>
            <!-- <tr>        
                <td class="capitalize">gambar 2</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <button type="button" class="btn btn-sm btn-dark  float-right"  v-if="cordova"   @click="camera2"><span class="typcn typcn-upload"></span></button>
                        <input  type="file" style="width:100px;" id="gambar2" name="gambar2" class="form-control form-control-sm" placeholder="gambar2" @change="upload2" >
                    </div>
                    <input  type="text" id="gambar2" name="gambar2" class="form-control form-control-sm" placeholder="gambar2" v-model="dataproduk.gambar2">
                </td>
            </tr> -->
          </table>
            </div>
            <div class="col-sm-4">
              <table class="table table-sm table-responsive">
                 <tr>        
                <td class="capitalize">harga jual</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <input required type="number" id="harga" name="harga" class="form-control form-control-sm" placeholder="harga" v-model="dataproduk.harga" >
                    </div>
                </td>
            </tr>
             <tr>        
                <td class="capitalize">harga beli/1</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <input required type="number" id="harga_beli" name="harga_beli" class="form-control form-control-sm" placeholder="harga_beli" v-model="dataproduk.harga_beli" >
                    </div>
                </td>
            </tr>
             <!-- <tr>        
                <td class="capitalize">harga grosir</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <input required type="number" id="harga_grosir" name="harga_grosir" class="form-control form-control-sm" placeholder="harga_grosir" v-model="dataproduk.harga_grosir" >
                    </div>
                </td>
            </tr> -->
             <tr>        
                <td class="capitalize">diskon (persen/%)</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <input required type="number" min="0" max="90" id="diskon" name="diskon" class="form-control form-control-sm" placeholder="diskon" v-model="dataproduk.diskon" >
                    </div>
                </td>
            </tr>
             <tr>        
                <td class="capitalize">stok awal</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                        <input required type="number" id="stok" name="stok" class="form-control form-control-sm" placeholder="stok" v-model="dataproduk.stok" >
                    </div>
                </td>
            </tr>
              <tr>        
                <td class="capitalize">type</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                  <select required class='form-control' v-model="dataproduk.type">
                  <option value="1">Jual</option>
                  <option value="2">Stok</option>
                  <option value="3">Jasa</option>
                  </select>
                </td>
            </tr>
            <!-- <tr>        
                <td class="capitalize">gambar 3</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                    <button type="button" class="btn btn-sm btn-dark  float-right"  v-if="cordova"  @click="camera3"><span class="typcn typcn-upload"></span></button>
                        <input  type="file" style="width:100px;" id="gambar3" name="gambar3" class="form-control form-control-sm" placeholder="gambar3" @change="upload3" >
                    </div>
                    <input  type="text" id="gambar3" name="gambar3" class="form-control form-control-sm" placeholder="gambar3" v-model="dataproduk.gambar3">
                </td>
            </tr>
            <tr>        
                <td class="capitalize">gambar 4</td>
                <td class="pl-2 pr-2">:</td>
                <td>
                    <div class="sm-form ">
                    <button type="button" class="btn btn-sm btn-dark  float-right"  v-if="cordova"   @click="camera4"><span class="typcn typcn-upload"></span></button>
                        <input  type="file" style="width:100px;" id="gambar4" name="gambar4" class="form-control form-control-sm" placeholder="gambar4" @change="upload4" >
                    </div>
                    <input  type="text" id="gambar4" name="gambar4" class="form-control form-control-sm" placeholder="gambar4" v-model="dataproduk.gambar4">
                </td>
            </tr> -->
              </table>
            </div>
            <div class="col-sm-4">
              <table class="table table-sm table-responsive">
                 <tr>        
                    <td colspan="3">
                      <div class="sm-form">
                      <textarea
                      style="height:350px"
                      type="text"
                      id="detail"
                      name="detail"
                      rows="2"
                      placeholder="detail..."
                      class="form-control md-textarea"
                      v-model="dataproduk.detail"
                      ></textarea>
                      </div>
                    </td>
                </tr>
              </table>
            </div>
          </div>
          
          <div v-if="dataproduk.stok_akhir">
            <p class="text-right text-xl font-bold">
            Stok Akhir : {{dataproduk.stok_akhir}}
            </p>
          </div>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span>  Proses {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  dataproduk = {};
                  generateBarcode();
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          <!-- END BUTTON PART -->
          </div>
        </div>
        <div class="col-sm-12 p-2" style="font-size:12px;">
          <div class="shadow bg-white rounded-lg">
            <div v-if="datanya.length>0" class="p-2">
               <div class="float-right">
                <vue-json-excel :data="datanya">
                  <button type="button" class="btn btn-sm btn-dark">
                    Export <span class="typcn typcn-chart-area"></span>
                  </button>
                </vue-json-excel>
              </div>
              <div class="ml-2">
                <p class="font-semibold pl-2">Select Type : </p>
                <select class='form-control' v-model="type" style="width:100px;">
                  <option value="">ALL</option>
                  <option value="1">Jual</option>
                  <option value="2">Stok</option>
                  <option value="3">Jasa</option>
                </select>
              </div>
              <div v-if="reload">
                <Btables :tables="td"  :cari="['']" :hide="['tanggal_w','type','satuan','penginput','detail','harga_grosir','gambar','gambar1','gambar2','gambar3','gambar4','tag','star5','star4','star3','star2','star1']" @selected="ambil" ref="btable" />
              </div>
              <div v-else style="height:300px;">
                <p class="text-center font-semibold">Item Tidak Ada ...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
     <div class="d-block d-sm-none"><br><br><br><br></div>

  </div>
</template>

<script>
import JsBarcode from 'jsbarcode'
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
import firebase from 'firebase'
import uniqid from 'uniqid'
let rdb = firebase.database()
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      lihatbarcode:false,
      reload:true,
      dataproduk: {
        diskon:0,
        stok:0
      },
      type_kategori:null,
      type:'',
      kategoris:[],
      satuans:[],
      btn: "tambah",
      datanya:[],
      masuk:[],
      keluar:[],
      transaksi:[],
      stok:0,
      cordova:false
    };
  },
  computed:{
    td(){
      let that=this 
      let data = this.datanya;
      this.reload=false;
      data=data.filter(e=>{
        if(e.type.indexOf(that.type)!=-1){
          return e 
        }
      })
      if(this.type_kategori){
        if(this.tdkategori.length>0){
          data=data.filter(e=>e.kategori==this.tdkategori[0].id)
        }
      }
      setTimeout(() => {
        this.reload=true;
      }, 500);
      return data
    },
    tdkategori(){
      let that=this;
      let data=this.kategoris;
      if(this.type_kategori){
        data=data.filter(e=>e.kategori==this.type_kategori)
      }
      return data;
    }
  },
  methods: {
    scanning(){
            let that=this
             if (typeof cordova == "object") {
                document.addEventListener("deviceready", function() {
                    cordova.plugins.barcodeScanner.scan(
                    function (result) {
                       that.dataproduk.barcode=result.text
                        that.$forceUpdate();
                        that.generateBarcode2()
                        sdb.alert('Berhasil Scan','bg-green-400' )
                    },
                    function (error) {
                        sdb.alert("Scanning failed: " + error, 'bg-red-400')
                    },
                    {
                        preferFrontCamera : false, // iOS and Android
                        showFlipCameraButton : true, // iOS and Android
                        showTorchButton : true, // iOS and Android
                        torchOn: false, // Android, launch with the torch switched on (if available)
                        saveHistory: true, // Android, save scan history (default false)
                        prompt : "Place a barcode inside the scan area", // Android
                        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                        formats : "QR_CODE,PDF_417,DATA_MATRIX,UPC_A,UPC_E,EAN_8,EAN_13,CODE_39,CODE_93,CODE_128,CODABAR,ITF,RSS14,RSS_EXPANDED,AZTEC", // default: all but PDF_417 and RSS_EXPANDED
                        orientation : "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
                        disableAnimations : true, // iOS
                        disableSuccessBeep: false // iOS and Android
                    }
                );
                });
            }
        },
    generateBarcode(){
      
      let bar='0000000000000';
      sdb.collection("app_kasir_produk",false).doc().select(`select * from app_kasir_produk order by id`).then(res => {
        let hasil=bar+res.length
          // console.log(hasil.slice((hasil).length-13,hasil.length))
          this.dataproduk.barcode=hasil.slice((hasil).length-13,hasil.length);
            JsBarcode("#barcode2", this.dataproduk.barcode);
          this.$forceUpdate();
      });
    },
    generateBarcode2(){
          JsBarcode("#barcode2", this.dataproduk.barcode);
          this.$forceUpdate();
    },
      async cekstok(item){
        console.log(this.dataproduk)
          let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.dataproduk.id_produk}' order by id`).then(res => {
         if(!this.skip_ambil_diskon){
             this.dataproduk.diskon=res[0].diskon
         }
          this.dataproduk.harga=res[0].harga
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.dataproduk.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar").doc().get("*",`where id_produk='${this.dataproduk.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.dataproduk.id_produk}' order by tb1.id`).then(res => {
          console.log('res',res);
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
      stok=stok+(masuk-keluar-transaksi)
      this.stok=stok;
      this.$forceUpdate();
    },
      getKategori(){
          //doc adalah select *
          sdb.collection("app_kasir_produk_kategori").doc().get("*","order by id").then(res => {
              this.kategoris=res;
          });
      },
       getSatuan(){
          //doc adalah select *
          sdb.collection("app_kasir_produk_satuan").doc().get("*","order by id").then(res => {
              this.satuans=res;
          });
      },
    async getData(){
      //doc adalah select *
      let that=this;
      console.log('1')
      this.masuk=await sdb.collection("app_kasir_stok_masuk").doc().get("*","order by id").then(res => {
          return res
      });
      console.log('2',this.masuk)
      this.keluar=await sdb.collection("app_kasir_stok_keluar").doc().get("*","order by id").then(res => {
          return res
      });
      console.log('3',this.keluar)
      // this.transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota order by tb1.id`).then(res => {
      //     return res
      // });
      console.log('transaksi',this.transaksi)
      // this.transaksi=await sdb.collection("app_kasir_transaksi_nota").doc().get("*","tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota order by tb1.id").then(res => {
      //     return res
      // });
      sdb.collection("app_kasir_produk",false).doc().select(`select * from app_kasir_produk`).then(res => {
        console.log('res',res)
        let data =res;
        data=data.map(e=>{
          return {
            ...e,
            stok_akhir:parseInt(e.stok)+(that.masuk.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0)-that.keluar.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0)-that.transaksi.reduce((k,n)=>{if(n.id_produk==e.id){  return k+parseInt(n.jumlah)}else{  return k}},0))
          }
        })
        this.datanya=data;
        this.$forceUpdate();
      });
      
    },
    ambil(data) {
      this.dataproduk = data;
      this.btn = "update";
      this.$forceUpdate();
    },
    async submit() {
      let that=this
      this.dataproduk.penginput=this.$store.state.users.id
      delete this.dataproduk.stok_akhir;
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di dataproduk
          delete this.dataproduk.id;
          this.dataproduk.star5=1;
          this.dataproduk.star4=0;
          this.dataproduk.star3=0;
          this.dataproduk.star2=0;
          this.dataproduk.star1=0;
          await sdb
            .collection("app_kasir_produk")
            .doc()
            .set(this.dataproduk)
              that.getData();
              that.dataproduk={
                  diskon:0,
                  stok:0
              }
              that.generateBarcode()
            // that.$nuxt.$emit('getData')
        } else if (this.btn == "update") {
         await sdb
            .collection("app_kasir_produk")
            .doc()
            .set(this.dataproduk)
              that.getData();
              that.dataproduk={
                diskon:0,
                stok:0
              }
              that.generateBarcode()
            // that.$nuxt.$emit('getData')
        } else if (this.btn == "delete") {
         await sdb
            .collection("app_kasir_produk")
            .doc(this.dataproduk.id)
            .delete()
              that.getData();
              that.dataproduk={
                diskon:0,
                stok:0
              }
              that.generateBarcode()
            // that.$nuxt.$emit('getData')
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
    upload1(){
      let file = document.querySelector("#gambar1"); // berikan id pada input file
        sdb.collection('tbuser').upload(file).then(res=>{
            console.log(res)
            this.dataproduk.gambar1=res;
            this.$forceUpdate();
        })
    },
    upload2(){
      let file = document.querySelector("#gambar2"); // berikan id pada input file
        sdb.collection('tbuser').upload(file).then(res=>{
            console.log(res)
            this.dataproduk.gambar2=res;
            this.$forceUpdate();
        })
    },
    upload3(){
      let file = document.querySelector("#gambar3"); // berikan id pada input file
        sdb.collection('tbuser').upload(file).then(res=>{
            console.log(res)
            this.dataproduk.gambar3=res;
            this.$forceUpdate();
        })
    },
    upload4(){
      let file = document.querySelector("#gambar4"); // berikan id pada input file
        sdb.collection('tbuser').upload(file).then(res=>{
            console.log(res)
            this.dataproduk.gambar4=res;
            this.$forceUpdate();
        })
    },
     
    getFileEntry (fileURL) {
        return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(
            fileURL,
            fileEntry => {
            resolve(fileEntry)
            },
            err => {
            reject(err)
            }
        )
        })
    }, 
    async getFile(fileEntry) {
        try {
        return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
        } catch (err) {
        console.log(err);
        }
    },
    async camera1(){
      let that=this;
      if (typeof cordova == "object") {
         document.addEventListener("deviceready", onDeviceReady, false);
          function onDeviceReady() {
             navigator.camera.getPicture(onSuccess, onFail, { quality: 50,
                  correctOrientation:true,
                  destinationType: Camera.DestinationType.FILE_URI });

              function onSuccess(imageURI) {
                  // var image = document.getElementById('myImage');
                  // image.src = imageURI;
                  that.processPicture1(imageURI)
              }

              function onFail(message) {
                  alert('Failed because: ' + message);
              }
          }
      }
    },
    async processPicture1(path) {
         let that=this;
          const imgFileEntry = await this.getFileEntry(path)
          const imgFile = await this.getFile(imgFileEntry)
          setTimeout(() => {
                  sdb.collection('tbuser').upload2(imgFile).then(res=>{
                      that.dataproduk.gambar1=res;
                      that.$forceUpdate();
                  })
          }, 2000);
        },
      async camera2(){
      let that=this;
      if (typeof cordova == "object") {
         document.addEventListener("deviceready", onDeviceReady, false);
          function onDeviceReady() {
             navigator.camera.getPicture(onSuccess, onFail, { quality: 50,
                  correctOrientation:true,
                  destinationType: Camera.DestinationType.FILE_URI });

              function onSuccess(imageURI) {
                  // var image = document.getElementById('myImage');
                  // image.src = imageURI;
                  that.processPicture2(imageURI)
              }

              function onFail(message) {
                  alert('Failed because: ' + message);
              }
          }
      }
    },
    async processPicture2(path) {
      let that=this;
          const imgFileEntry = await this.getFileEntry(path)
          const imgFile = await this.getFile(imgFileEntry)
          setTimeout(() => {
                  sdb.collection('tbuser').upload2(imgFile).then(res=>{
                      that.dataproduk.gambar2=res;
                      that.$forceUpdate();
                  })
          }, 2000);
      },
      async camera3(){
      let that=this;
      if (typeof cordova == "object") {
         document.addEventListener("deviceready", onDeviceReady, false);
          function onDeviceReady() {
             navigator.camera.getPicture(onSuccess, onFail, { quality: 50,
                  correctOrientation:true,
                  destinationType: Camera.DestinationType.FILE_URI });

              function onSuccess(imageURI) {
                  // var image = document.getElementById('myImage');
                  // image.src = imageURI;
                  that.processPicture3(imageURI)
              }

              function onFail(message) {
                  alert('Failed because: ' + message);
              }
          }
      }
    },
    async processPicture3(path) {
      let that=this;
          const imgFileEntry = await this.getFileEntry(path)
          const imgFile = await this.getFile(imgFileEntry)
          setTimeout(() => {
                  sdb.collection('tbuser').upload2(imgFile).then(res=>{
                      that.dataproduk.gambar3=res;
                      that.$forceUpdate();
                  })
          }, 2000);
      },
      async camera4(){
      let that=this;
      if (typeof cordova == "object") {
         document.addEventListener("deviceready", onDeviceReady, false);
          function onDeviceReady() {
             navigator.camera.getPicture(onSuccess, onFail, { quality: 50,
                  correctOrientation:true,
                  destinationType: Camera.DestinationType.FILE_URI });

              function onSuccess(imageURI) {
                  // var image = document.getElementById('myImage');
                  // image.src = imageURI;
                  that.processPicture4(imageURI)
              }

              function onFail(message) {
                  alert('Failed because: ' + message);
              }
          }
      }
    },
    async processPicture4(path) {
      let that=this;
          const imgFileEntry = await this.getFileEntry(path)
          const imgFile = await this.getFile(imgFileEntry)
          setTimeout(() => {
                  sdb.collection('tbuser').upload2(imgFile).then(res=>{
                      that.dataproduk.gambar4=res;
                      that.$forceUpdate();
                  })
          }, 2000);
      },
  },
  async mounted() {
    let that=this;
   
    if (typeof cordova == "object") {
      that.cordova=true;      
    }
    rdb.ref(`/scan/${that.$store.state.users.id}/barcode`).on('value',(e)=>{ //read
          that.dataproduk.barcode=e.val().id
          that.$forceUpdate();
          // that.format=e.val().format
    }) 
    this.generateBarcode()
    this.getData();
    this.getKategori();
    this.getSatuan();
     if(this.$route.query.kategori){
      this.type_kategori=this.$route.query.kategori;
    }
  },
};
</script>